@import url("https://fonts.googleapis.com/css2?family=Inter:wght@800&display=swap");

$BG-Colour: #222222;
$Font-Colour: #ffffff;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "helvetica";
  color: $Font-Colour;
  background-color: $BG-Colour;
  overflow-x: hidden;
}

.navBar {
  color: $Font-Colour;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100vw;
  overflow: hidden;

  a {
    text-decoration: none;
  }

  h4 {
    font-size: 36px;
    color: $Font-Colour;
    font-family: "Inter", sans-serif;
  }
  padding: 1rem;
  img {
    width: 1.5rem;
  }
}

.socialMediaIcons {
  display: flex;
  gap: 1rem;
  padding-right: 1rem;
}

.Hero {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  .viewButton {
    color: $BG-Colour;
    background-color: $Font-Colour;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    font-weight: 500;
    cursor: pointer;
    animation: 1s ease-out heroSlideUp;
    overflow: hidden;
    text-decoration: none;
  }

  h1 {
    animation: 1s ease-out heroSlideUp;
  }

  h3 {
    animation: 1s ease-out heroSlideUp;
  }
}

.About-Whole {
  width: 100vw;
  display: flex;
  justify-content: center;
  margin-bottom: 10rem;

  .About {
    display: flex;
    padding: 2rem;
    gap: 4rem;
    max-width: 1280px;
    align-items: center;
  }

  .About-Me {
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .About-Skills {
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    .About-Skills-Tech {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    .About-Skills-Skills {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    .techIcons {
      display: flex;
      gap: 1rem;
      flex-wrap: wrap;
    }

    img {
      width: 3rem;
    }

    .skillsList {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;

      .skill {
        background-color: $Font-Colour;
        color: $BG-Colour;
        padding: 8px 12px;
      }
    }
  }
}

.Portfolio-Whole {
  display: flex;
  justify-content: center;

  .Portfolio {
    display: flex;
    flex-direction: column;
    max-width: 1280px;
    text-align: left;
    gap: 4rem;
    padding: 2rem;

    .button-more {
      background-color: $Font-Colour;
      color: $BG-Colour;
      border: none;
      padding: 0.5rem 1rem;
      border-radius: 0.25rem;
      text-align: center;
      cursor: pointer;
      font-weight: 500;
    }
  }
}

.portfolioItem {
  display: flex;
  align-items: center;
  gap: 2rem;

  img {
    width: 2rem;
    margin-right: 1rem;
  }

  .portfolioImg {
    width: 30%;

    img {
      aspect-ratio: 16/10;
      object-fit: cover;
      object-position: top;
      width: 100%;
      border-radius: 0.5rem;
    }
  }

  .portfolioDesc {
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    a {
      margin-right: 1rem;
    }
  }
}

.Contact {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 6rem;
  flex-direction: column;

  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1280px;
    padding: 2rem;

    input,
    textarea {
      background-color: $Font-Colour;
      padding: 0.5rem 1rem;
      margin-top: 1rem;
      border: none;
      font-size: 16px;
      color: $BG-Colour;
    }

    button {
      border: solid 1px $Font-Colour;
      margin-top: 1rem;
      padding: 0.5rem 1rem;
      font-weight: 500;
      cursor: pointer;
    }
  }
}

.projectsPage {
  margin-top: 8rem;
  display: flex;
  justify-content: center;
  padding: 2rem;

  .projectsList {
    max-width: 1280px;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
}

.footer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 6rem;
  margin-bottom: 1rem;
}

@media screen and (max-width: 768px) {
  .About {
    flex-direction: column;

    .About-Me {
      width: 100%;
    }

    .About-Skills {
      width: 100%;
      flex-direction: row;
    }
  }
}

@media screen and (max-width: 480px) {
  .Hero {
    align-items: baseline;
    padding: 1rem;
    h1 {
      font-size: 48px;
      animation: 1s ease-out heroSlideLeft;
    }
    h3 {
      animation: 1s ease-out heroSlideLeft;
    }
  }

  .About {
    .About-Skills {
      flex-direction: column;
    }
  }

  .portfolioItem {
    flex-direction: column;
    .portfolioImg {
      width: 100%;
    }
    .portfolioDesc {
      width: 100%;
    }
  }
}

@keyframes heroSlideUp {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes heroSlideLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}
